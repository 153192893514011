import activity from './m-personal-objective.vue';

export default activity;

export const componentRegistrationData = {
  name: 'objective',
  icon: 'mdi-star',
  description: 'OKR for employer and students',
  status: true,
  setupRequired: true,
  fields: [
    {
      roles: ['employer', 'student'],
      name: 'm-personal-objective',
      type: 'molecule',
      position: 0,
      preset: {},
      setup: {},
      value: {}
    },
    {
      roles: ['employer'],
      name: 'm-save-only',
      type: 'molecule',
      position: 1,
      preset: {},
      setup: {},
      value: {}
    }
  ]
};
