
import { defineComponent, ref, computed } from '@vue/composition-api';
import { Field } from '../index';

interface OrganizerSetupOKR {
  objective: string;
  keyResults: string[];
}
interface ParticipantOKR {
  objective: string;
  keyResults: string[];
  okrAck: boolean[];
}

interface OKRField extends Field {
  value: ParticipantOKR;
  setup: OrganizerSetupOKR;
}
export default defineComponent({
  name: 'MPersonalObjective',
  props: {
    fetchProgram: {
      required: false,
      type: [Object, Function],
      default: () => {}
    },
    adkType: {
      required: false,
      type: String,
      default: ''
    },
    userType: {
      required: true,
      type: String,
      default: ''
    },
    value: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  setup(_props, _ctx) {
    const programDocument: any = computed({
      get: () => _props.value,
      set: newVal => {
        _ctx.emit('input', newVal);
      }
    });

    const adkIndex = computed(() => {
      const indx = programDocument.value?.data.adks?.findIndex(adk => {
        if (adk) {
          return adk.name === 'objective';
        }
        return false;
      });
      return indx;
    });

    const fieldIndex = computed(() => {
      const Fdindx = programDocument.value?.data?.adks?.[adkIndex.value]?.fields?.findIndex(
        field => {
          if (field.name) {
            return field.name === 'm-personal-objective';
          }
          return false;
        }
      );
      return Fdindx;
    });

    const fieldValue = computed<OKRField>({
      get: () => programDocument.value?.data?.adks?.[adkIndex.value]?.fields[fieldIndex.value],
      set: newVal => {
        if (fieldValue.value)
          programDocument.value.data.adks[adkIndex.value].fields[fieldIndex.value] = newVal;
      }
    });

    const userIsEmployer = ref(_props.userType === 'organizer');

    const organizerOKR = computed<OrganizerSetupOKR>({
      get: () => fieldValue.value.setup,
      set: newVal => {
        fieldValue.value.setup = newVal;
      }
    });
    const participantOKR = computed<ParticipantOKR>({
      get: () => fieldValue.value.value,
      set: newVal => {
        fieldValue.value.value = newVal;
      }
    });

    const organizerActions = {
      addKeyResult() {
        organizerOKR.value.keyResults.push('');
      },
      removeKeyResult(index) {
        organizerOKR.value.keyResults.splice(index, 1);
      }
    };

    const participantActions = {
      addKeyResults() {
        participantOKR.value.keyResults.push('');
      },

      removeKeyResult(index) {
        participantOKR.value.keyResults.splice(index, 1);
      }
    };

    const allOrganizerOKRAcknowledged = computed(() => {
      const allValid = Boolean(participantOKR.value.okrAck?.filter(ack => !ack));
      return allValid;
    });

    return {
      fieldValue,
      participantOKR,
      organizerOKR,
      organizerActions,
      participantActions,
      allOrganizerOKRAcknowledged,
      userIsEmployer
    };
  }
});
